import React from "react";
import logo_wsp from './images/icon_wsp_white.svg'
import {getMessage} from "./util";

const Dudas =(props)=> {

        return (
            <div className="row justify-content-center align-items-center dudas EvColorGrisLight ">
                <div className="col-12 col-md-6  dudas-box p-4">
                    <h2 className="font-weight-bold mt-4 mb-4 text-center ">{getMessage("titulo_ayuda_1", props.messages, props.language)}<br/>{getMessage("titulo_ayuda_2", props.messages, props.language)}</h2>
                    <h4 className="font-italic h4 mb-4 text-center">{getMessage("mesa_ayuda", props.messages, props.language)}</h4>
                    <div><span className="linespanblack"></span></div>
                </div>
                <div className="col-12 col-md-6 text-center h5 mt-4 font-weight-bold">
                    <p>{getMessage("correo", props.messages, props.language)} / {getMessage("correo_soporte", props.messages, props.language)} </p>
                    {props.language === "en"
                        ?
                        <a href="https://api.whatsapp.com/send?phone=56985574361&text=Hello,%20I%20need%20help%20with%20the%20OAS%202024-2026%20Election%20Committee%20voting" target="_blank"
                           className="btn btn-wsp EvColor " role="button" aria-pressed="true">
                            <img src={logo_wsp}
                                 className="img-wsp"
                                 alt="soporte whatsapp"
                            />
                            {getMessage("soporte_wsp", props.messages, props.language)}
                        </a>
                        :
                        <a href="https://api.whatsapp.com/send?phone=56985574361&text=Hola,%20necesito%20ayuda%20en%20la%20Votaci%C3%B3n%20del%20Comit%C3%A9%20de%20Elecciones%202024-2026%20OEA" target="_blank"
                           className="btn btn-wsp EvColor " role="button" aria-pressed="true">
                            <img src={logo_wsp}
                                 className="img-wsp"
                                 alt="soporte whatsapp"
                            />
                            {getMessage("soporte_wsp", props.messages, props.language)}
                        </a>
                    }
                </div>
            </div>

        );
}

export default Dudas;
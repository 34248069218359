import React from 'react';
import verificar_img from "../images/email_clave.svg";
import elegir_img_x from "../images/elegir_x.svg"
import elegir_img_y from "../images/elegir_b-01.svg";
import confirmar_img from "../images/confirmar respuestas_b-01.svg";
import {getMessage} from "../util";
import elegir_img_en from "../images/en/elegir_xEN.svg"
import confirmar_img_en from "../images/en/confirmar respuestas_bEN.svg"
import verificar_img_en from "../images/en/id_clavebc_bEN.svg"


const IdentityPasswordNone = (props) =>{
    const elegir_img = (props.configuration.square_image ? elegir_img_x : elegir_img_y);
   
        return(
            <div className="row mb-4">
                <div className="col-12 col-sm-4 text-center">
                    <p className="como-title mt-4">1. {getMessage("paso_elegir", props.messages, props.language)}</p>
                    <p className="font-italic h5 vp-75">{getMessage("instruccion_elegir", props.messages, props.language)}</p>
                    {props.language === "en" ?
                        <img src={elegir_img_en} className="img-max-300" alt="Cómo"/>
                        :
                        <img src={elegir_img} className="img-max-300" alt="Cómo"/>
                    }

                </div>

                <div className="col-12 col-sm-4 text-center border-left">
                    <p className="como-title mt-4">2. {getMessage("paso_confirmar", props.messages, props.language)}</p>
                    <p className="font-italic h5 vp-75">{getMessage("instruccion_confirmar", props.messages, props.language)}</p>
                    {props.language === "en" ?
                        <img src={confirmar_img_en} className="img-max-300" alt="Confirmar"/>
                        :
                        <img src={confirmar_img} className="img-max-300" alt="Confirmar"/>
                    }
                </div>

                <div className="col-12 col-sm-4 text-center border-left">
                    <p className="como-title mt-4">3. {getMessage("paso_votar", props.messages, props.language)}</p>
                    <p className="font-italic h5 ">{getMessage("instruccion_votar_clave", props.messages, props.language)}</p>
                    <p className="font-italic h5 vp-100">{getMessage("instruccion_votar_clave2", props.messages, props.language)}</p>
                    {props.language === "en" ?
                        <img src={verificar_img_en} className="img-max-300" alt="Votar"/>
                        :
                        <img src={verificar_img} className="img-max-300" alt="Votar"/>
                    }

                </div>


            </div>
        )
}
export default IdentityPasswordNone;
import React, {Component, Fragment} from 'react';
import './App.css';
import Election from "./Election";
import axios from "axios";
import Footer from "./Footer";
import Header from "./Header";
import LoadingSpinner from "./LoadingSpinner";
import ListElection from "./ListElection";
import {getMessage} from "./util";
import messages from './messages.json'

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            client: null,
            open: true,
            rc: [],
            language: 'default',
        };
    }

     componentDidMount() {
         const host = window.location.hostname.toLocaleLowerCase().startsWith("www.") ? window.location.hostname.substr(4) : window.location.hostname;
         this.loadBase(host);
     }

    loadBase = name => {
        this.setState({loading: true}, () => {
            const rd = Math.random().toString(36).substring(7);
            axios({
                method: "get",
                //url: "/data/prueba.json"+"?"+rd
                url: process.env.REACT_APP_BASE_URL + "/" + name + ".json"+"?"+rd
            })
                .then(response => {
                    this.setState({loading: false, client: response.data});
                })
                .catch(error => {
                    this.setState({loading: false});
                })
        });
    }

    handleLanguageChangeEnglish = () => {
        this.setState({ language: "en" });
    }
    handleLanguageChangeSpanish = () => {
        this.setState({ language: "es" });
    }

    render() {


        return (
            <Fragment>
                {this.state.open ?
                    <OpenElection state={this.state} language={this.state.language} handleLanguageChangeEnglish={this.handleLanguageChangeEnglish} handleLanguageChangeSpanish={this.handleLanguageChangeSpanish}/> :
                    <NoElection state={this.state} language={this.state.language} handleLanguageChangeEnglish={this.handleLanguageChangeEnglish} handleLanguageChangeSpanish={this.handleLanguageChangeSpanish}/>
                }

            </Fragment>

        );
    }
}

class OpenElection extends Component {


    render() {
        return (
            <div className="container-fluid">
                <Header language={this.props.language} handleLanguageChangeEnglish={this.props.handleLanguageChangeEnglish} handleLanguageChangeSpanish={this.props.handleLanguageChangeSpanish} />
                {this.props.state.loading ? (
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-11 text-center m-4">
                            <LoadingSpinner />
                        </div>
                    </div>
                ) : (
                    <Fragment>
                        {this.props.state.client ? (
                            <Election
                                messages={this.props.state.client.elections[0].messages}
                                config={this.props.state.config}
                                election={this.props.state.client.elections[0]}
                                language={this.props.language}
                            />
                        ) : (
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-11 text-center m-4">
                                    <h2 className="m-4">{getMessage("not_found", null, this.props.language)}</h2>
                                </div>
                            </div>
                        )}
                    </Fragment>
                )}
                {this.props.state.client ? (
                    <>
                        <br></br>
                        <Footer
                        election={this.props.state.client ? this.props.state.client.elections[0] : null}
                        messages={this.props.state.client.elections[0].messages}
                        language={this.props.language}
                        />
                    </>

                ) : (
                    <>
                        <br></br>
                        <Footer
                            election={this.props.state.client ? this.props.state.client.elections[0] : null}
                            language={this.props.language}
                        />
                    </>

                )}
            </div>
        );
    }
}

class NoElection extends Component {

    render() {
        return (
            <div className="container-fluid">
                <Header language={this.props.language} handleLangugageChangeEnglish={this.props.handleLanguageChangeEnglish} handleLanguageChangeSpanish={this.props.handleLanguageChangeSpanish} />

                {this.props.state.loading ? (
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-11  text-center m-4 ">
                            <LoadingSpinner/>
                        </div>
                    </div>
                ) : (
                    <Fragment>
                        <ListElection state={this.props.state} election={this.props.state.client.elections}/>
                    </Fragment>
                )}
                <Footer  election={this.props.state.client ? this.props.state.client.elections[0] : null}/>
            </div>
        );
    }

}




export default App;
import React, { Fragment} from "react";

const Encabezado =(props)=> {
    return (
        <Fragment>
            <div className="row justify-content-center">
                <div className="col-12 col-sm-11  text-center m-4 ">
                    <img
                        className="img-fluid logo"
                        alt="Logo"
                        src={props.election.configuration.logo_url}
                    />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-12 col-sm-11 text-center">
                    {props.language === "en"
                        ?
                        <>
                            <h2 className="font-weight-bold">Welcome to the Electoral Process 2024!</h2>
                            <h3 className="font-italic">Demo - Elections Committee 2024-2026</h3>
                            <h5>The voting takes place between June 6th at 9:00 AM and June 7th at 5:30 PM (Washington time)</h5>

                        </>
                        :
                        <>
                        <h2 className="font-weight-bold">{props.election.name}</h2>
                            <h3 className="font-italic">{props.election.organization_name}</h3>
                            <h5>La votación transcurre entre el día 6 de junio a las 09:00 hrs. y el día 7 de junio a las 17:30 hrs. (horario Washington)</h5>
                        </>
                    }


                </div>
            </div>
        </Fragment>
    )
}

export default Encabezado;
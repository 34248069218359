import default_messages from "./messages.json";

export const getMessage = (key, messages, language) => {
    const getDefaultMessage = (key) => {
        if (!default_messages[language] || !default_messages[language][key]) {
            if (!default_messages.default[key]) {
                return (default_messages.default.nokey);
            }
            return (default_messages.default[key]);
        } else {
            return (default_messages[language][key]);
        }
    };

    if (messages && messages[language] && messages[language][key]) {
        return (messages[language][key]);
    }

    if (messages && messages.default && messages.default[key]) {
        return (messages.default[key]);
    }

    return (getDefaultMessage(key));
};

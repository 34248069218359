import React from "react";
import logo_img from "./images/logo.svg";
import en_flag from "./images/enflag.png";
import es_flag from "./images/esflag.png";

const Header = (props) => {
    return (
        <div className="row EvColorGrisLight align-items-center">
            <div className="col-6">
                <img src={logo_img} className="img-fluid logoEV" alt="logo" />
            </div>
            <div className="col-6 d-flex justify-content-end align-items-center">

                {props.language === "en" ?
                    <button  className="btn" onClick={props.handleLanguageChangeSpanish}>
                        <a>
                            <img src={es_flag} alt="" className="flag"/> Español</a>
                    </button>
                    :
                    <button className="btn" onClick={props.handleLanguageChangeEnglish}>
                        <a>
                            <img src={en_flag} alt="" className="flag"/> English</a>
                    </button>
                }

            </div>
            
        </div>
    );
};

export default Header;
